/* Title Styling */
.levelcast-title {
    width: auto; /* Take up full width of sidebar wrapper */
    height: auto; /* Take up full height of sidebar wrapper */
    position: absolute;
    top: 20px; /* Adjust as needed */
    font-family: var(--font-primary);
    font-size: 20px;
    font-weight: bold;
    color: white;
    z-index: 1001; /* Ensure it's above the sidebar, which has a z-index of 1000 */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); Optional: add a shadow for better visibility */
}