.collapsible-section {
    margin: 10px 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.collapsible-section.custom_blocks {
    background: linear-gradient(135deg, rgba(0, 123, 255, 0.3), rgba(106, 13, 173, 0.3));
}

.collapsible-section.buildin_blocks {
    background: linear-gradient(135deg, rgba(255, 0, 0, 0.3), rgba(255, 165, 0, 0.3));
}

.collapsible-section:hover {
    background-color: #3a3a3a;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.collapsible-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #444;
    cursor: pointer;
    border-bottom: 1px solid #555;
    border-radius: 8px 8px 0 0;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.collapsible-header:hover {
    background-color: #555;
    background-image: var(--hover-gradient);
    filter: brightness(0.9) contrast(1.1) saturate(1.4);
    /* box-shadow: 0 0 15px rgba(58, 150, 255, 0.5); */
}

.collapsible-header h3 {
    margin: 0;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-shadow: 0 0 8px rgba(233, 236, 239, 0.6); /* Adds a subtle glowing text effect */
    transition: text-shadow 0.3s ease;

}

.collapsible-header.open h3 {
    animation: textGlow 1.5s infinite alternate;
}

.collapsible-header .toggle-icon {
    font-size: 24px;
    color: #ccc;
    transition: transform 0.3s ease, color 0.3s ease;
}

.collapsible-header .toggle-icon.open {
    transform: rotate(180deg);
    color: #ff6347;
}

.collapsible-content {
    max-height: 0;
    overflow: hidden;
    padding: 0 20px;
    background-color: rgba(44, 44, 44, 0.8);
    color: white;
    border-top: 1px solid #444;
    border-radius: 0 0 8px 8px;
    transition: max-height 0.4s ease, padding 0.3s ease, opacity 0.4s ease;
    opacity: 0;
    position: relative;
}

.collapsible-content.open {
    max-height: 200px;
    padding: 20px;
    opacity: 1;
}

.copy-button {
    position: absolute;
    top: 15px;
    right: 20px;
    background-color: #444;
    color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease, background-color 0.3s ease;
}

.copy-button:hover {
    background-color: #555;
}

.collapsible-section:hover .copy-button {
    opacity: 1;
}

@keyframes textGlow {
    0% {
        text-shadow: 0 0 8px rgba(233, 236, 239, 0.3);
    }
    50% {
        text-shadow: 0 0 15px rgba(58, 150, 255, 0.8);
    }
    100% {
        text-shadow: 0 0 8px rgba(233, 236, 239, 0.3);
    }
}