.builder-detail-container {
    position: relative;
    /* width: 100%; */
    padding: 20px;
    /* height: calc(100vh - 200px);
    overflow-y: auto; */
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    /* overflow-y: auto; */
}

.builder-section {
    margin-bottom: 30px;
}

.builder-section h3 {
    font-family: var(--font-secondary);
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.builder-input, .builder-text {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    color: var(--text-color);
    border: 1px solid var(--text-color);
    border-radius: var(--border-radius);
    padding: 10px;
    font-family: var(--font-secondary);
    font-size: 14px;
    line-height: 1.6;
    transition: border-color 0.3s ease;
}

.builder-input {
    resize: vertical;
}

.builder-content {
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    white-space: pre-wrap;
    word-wrap: break-word;
    resize: none;
    transition: height 0.1s ease-out;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) transparent;
}

.readonly-content {
    cursor: default;
    user-select: text;
    overflow-y: auto;
    max-height: 350px;
    scroll-behavior: smooth;
    white-space: pre-wrap;
    word-wrap: break-word;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) transparent;
}

.builder-content::-webkit-scrollbar,
.readonly-content::-webkit-scrollbar {
    width: 8px;
}

.builder-content::-webkit-scrollbar-track,
.readonly-content::-webkit-scrollbar-track {
    background: transparent;
}

.builder-content::-webkit-scrollbar-thumb,
.readonly-content::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 20px;
    border: 2px solid var(--background-color);
}

.builder-content::-webkit-scrollbar-thumb:hover,
.readonly-content::-webkit-scrollbar-thumb:hover {
    background-color: var(--accent-color);
}

.builder-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.builder-auto-execute {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: var(--border-radius);
}

.builder-auto-execute span {
    font-family: var(--font-secondary);
    font-size: 14px;
    color: var(--text-color);
}

/* Toggle Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--primary-color);
}

input:checked + .slider:before {
    transform: translateX(26px);
}

input:disabled + .slider {
    opacity: 0.5;
    cursor: not-allowed;
}

.back-button {
    padding: 10px 20px;
    background-color: var(--secondary-color);
    color: var(--text-color);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: var(--font-secondary);
    font-size: 14px;
    align-self: flex-start; /* Align button to the left */
    margin-top: 20px; /* Add some space above the button */
}

.back-button:hover {
    background-color: var(--accent-color);
}

.loading {
    font-family: var(--font-secondary);
    font-size: 18px;
    color: var(--text-color);
    text-align: center;
    padding: 40px;
}

@media (max-width: 768px) {
    .builder-detail-container {
        padding: 15px;
    }

    .builder-section h3 {
        font-size: 16px;
    }

    .builder-input, .builder-text {
        font-size: 13px;
    }
}

.textarea-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
}

.builder-input.builder-content {
    flex-grow: 1;
    font-size: 16px;
    line-height: 1.8;
    padding: 15px;
    /* border: 2px solid var(--border-color); */
    /* transition: border-color 0.3s ease, box-shadow 0.3s ease; */
    /* resize:none */
}

.builder-input.builder-content:focus {
    border-color: var(--primary-color);
    /* box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.2); */
}

.dynamic-button {
    position: absolute;
    left: -45px;
    background-color: var(--background-color);
    border: 2px solid var(--primary-color);
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    transition: all 0.3s ease;
    z-index: 10;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dynamic-button.active {
    background-color: var(--primary-color);
}

.dynamic-button img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
    filter: brightness(0) invert(1);
}

.dynamic-button:hover img {
    transform: rotate(90deg);
}

.dynamic-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(var(--primary-color-rgb), 0.3);
}

.dynamic-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(var(--primary-color-rgb), 0.2);
}

.building-blocks-suggestions {
    position: absolute;
    color: var(--text-color);
    background-color: rgba(var(--background-color-rgb), 0.95);
    border: none;
    border-radius: var(--border-radius);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
    width: 300px;
    padding: 10px 0;
    transition: all 0.3s ease;
    animation: fadeIn 0.3s ease-out;
    backdrop-filter: blur(5px);
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

.suggestion-item {
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    border-left: 3px solid transparent;
}

.suggestion-item:hover {
    background-color: rgba(var(--primary-color-rgb), 0.1);
    border-left-color: var(--primary-color);
}

.suggestion-item .building-block {
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 4px;
}

.suggestion-item .description {
    font-size: 12px;
    color: var(--text-color-secondary);
}

.highlight-block {
    color: var(--primary-color);
    font-weight: 600;
}

.editable-title {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-color);
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--primary-color);
    padding: 5px 10px;
    width: 100%;
    max-width: 400px;
    transition: border-color 0.3s ease;
}

.editable-title:focus {
    outline: none;
    border-color: var(--accent-color);
}

.saving-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--border-radius);
    background-color: rgba(0, 0, 0, 0.5);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: var(--text-color);
    animation: fadeIn 0.3s ease-out;
}

.saving-overlay p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
}

.builder-detail-container {
    position: relative;
    /* width: 100%; */
    padding: 20px;
    /* height: calc(100vh - 200px);
    overflow-y: auto; */
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    /* overflow-y: auto; */
}

.unsaved-changes-warning {
    background-color: var(--warning-color);
    color: var(--text-color);
    padding: 10px;
    border-radius: var(--border-radius);
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.builder-input.builder-content.invalid {
    border-color: var(--error-color);
}

.builder-input.builder-content.valid {
    border-color: var(--success-color);
}

/* Improve responsiveness */
@media (max-width: 768px) {
    .builder-detail-container {
        padding: 15px;
    }

    .editable-title {
        font-size: 20px;
        max-width: 100%;
    }

    .builder-auto-execute {
        flex-direction: column;
        align-items: flex-start;
    }

    .builder-auto-execute span {
        margin-top: 10px;
    }
}

/* Add a subtle transition for smoother UI changes */
.builder-input, .builder-text, .editable-title, .back-button {
    transition: all 0.3s ease;
}

.builder-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.textarea-wrapper.dark-mode .builder-input,
.textarea-wrapper.dark-mode .readonly-content {
    background-color: #1e1e1e;
    color: #ffffff;
    border-color: #444444;
}

.textarea-wrapper.dark-mode .highlight-block {
    background-color: #2c2c2c;
    color: #61dafb;
}

.textarea-wrapper.dark-mode .building-blocks-suggestions {
    background-color: #2c2c2c;
    border-color: #444444;
}

.textarea-wrapper.dark-mode .suggestion-item {
    border-bottom-color: #444444;
}

.textarea-wrapper.dark-mode .suggestion-item:hover {
    background-color: #3c3c3c;
}

.textarea-wrapper.dark-mode .building-block {
    color: #61dafb;
}

.textarea-wrapper.dark-mode .description {
    color: #a0a0a0;
}