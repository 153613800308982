/* UploadBox.css */
.upload-box {
    color: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: var(--font-secondary);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
    overflow-y: auto; /* Change back to auto */
}

.upload-box h2 {
    font-size: 1.8rem; /* Slightly smaller title */
    margin-bottom: 1.5rem; /* Reduced margin */
    color: white;
    align-self: flex-start;
}

.form-content {
    display: flex;
    width: 100%;
    flex: 1; /* Use flex instead of fixed height */
    gap: 2rem;
}

.form-fields-container {
    flex: 1;
    padding-right: 2rem; /* Reduced padding */
    padding-bottom: 2rem; /* Add some padding at the bottom */
    max-width: calc(100% - 500px); /* Adjust based on upload-area-container width */
    will-change: transform; /* Optimize for scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.form-fields {
    display: flex;
    flex-direction: column;
    gap: 0.75rem; /* Reduced gap between form groups */
    padding-bottom: 2rem; /* Add some padding at the bottom */
    
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5rem; /* Added margin between form groups */
    will-change: transform;
    transform: translateZ(0);
}

.form-group label {
    margin-bottom: 0.25rem; /* Reduced margin */
    font-size: 0.9rem; /* Slightly smaller label font */
    color: #ccc;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 0.5rem; /* Reduced padding */
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 0.9rem; /* Slightly smaller input font */
    transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    background-color: rgba(255, 255, 255, 0.2);
}

.form-group textarea {
    resize: vertical;
    min-height: 80px; /* Reduced minimum height for textareas */
}

.upload-area-container {
    flex: 0 0 380px; /* Slightly reduced width */
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Change to flex-start */
    overflow: visible; /* Change to visible */
    height: 100%; /* Take full height of parent */
    overflow: hidden; /* Prevent scrolling */
}

.upload-area {
    width: 100%;
    height: auto; /* Change to auto */
    max-height: 250px; /* Slightly reduced maximum height */
    border: 2px dashed rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 1.5rem; /* Reduced padding */
    background-color: rgba(255, 255, 255, 0.05);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.upload-area:hover {
    border-color: var(--primary-color);
    background-color: rgba(255, 255, 255, 0.1);
}

.file-loaded {
    border-style: solid;
    background-color: rgba(255, 255, 255, 0.1);
}

.browse-link {
    color: var(--primary-color);
    cursor: pointer;
}

.formats {
    font-size: 0.8rem;
    color: #aaa;
    margin-top: 0.5rem;
}

.file-input-label {
    color: var(--primary-color);
    cursor: pointer;
    text-decoration: underline;
    margin-top: 1rem;
    display: inline-block;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 1rem;
}

.progress {
    height: 100%;
    background-image: var(--hover-gradient);
    transition: width 0.4s ease;
}

.file-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 5px;
}

.remove-file-btn {
    background: none;
    border: none;
    color: #ff4c4c;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

.remove-file-btn:hover {
    color: #ff6c6c;
}

.upload-btn {
    align-self: center; /* Center the button horizontally */
    margin-top: 1rem; /* Reduced top margin */
    padding: 0.75rem 2rem;
    font-size: 1rem;
    color: white;
    background-color: var(--primary-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 1;
    width: 200px; /* Set a fixed width */
    visibility: visible; /* Ensure button is always visible */
    position: sticky; /* Make button sticky */
    bottom: 1rem; /* Stick to bottom with some padding */
    z-index: 10; /* Ensure button is above other content */
}

.upload-btn.ready {
    background-color:#333;
    background-image: var(--hover-gradient);
}

.upload-btn.not-ready {
    background-color: #666;
    cursor: not-allowed;
    opacity: 0.7;
}

.upload-btn:hover:not(:disabled) {
    background-color: var(--primary-color-dark);
}

.upload-btn:disabled {
    pointer-events: none;
}

.success-message {
    margin-top: 1rem; /* Reduced top margin */
    color: var(--primary-color);
    text-align: center;
}

.view-blocks-btn {
    margin-top: 1rem;
    padding: 0.75rem 2rem;
    background-image: var(--hover-gradient);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.view-blocks-btn:hover {
    opacity: 0.9;
}

.guests-section {
    display: flex;
    flex-direction: column;
    gap: 0.75rem; /* Reduced gap between guest forms */
    margin-bottom: 2rem; /* Add margin at the bottom */
}

.guest-form {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    padding: 0.75rem; /* Reduced padding */
}

.add-guest-btn,
.remove-guest-btn {
    padding: 0.4rem 0.8rem; /* Reduced padding */
    font-size: 0.8rem; /* Slightly smaller font */
    background-color: #333;
    background-image: var(--hover-gradient);
    color: #ffffff;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; /* Add margin at the bottom */
}

.add-guest-btn:hover,
.remove-guest-btn:hover {
    background-color: var(--primary-color-dark);
}

.remove-guest-btn {
    background-color: #ff4c4c;
}

.remove-guest-btn:hover {
    background-color: #ff6c6c;
}

@media (max-width: 1200px) {
    .form-content {
        flex-direction: column;
        height: auto;
        overflow: visible;
    }

    .form-fields-container {
        max-width: 100%;
        margin-bottom: 2rem;
        overflow-y: visible;
    }

    .upload-area-container {
        flex: 0 0 auto;
        width: 100%;
        height: auto;
    }

    .upload-area {
        height: 280px;
    }

    .upload-btn {
        position: static; /* Remove sticky positioning on mobile */
        margin-bottom: 1rem; /* Add some bottom margin */
    }
}

/* Remove the global rule for better initial load performance */
/* * {
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */