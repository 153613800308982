@import '../../styles/_variables.css';

/* Title styling */
.title {
    font-family: var(--font-primary);
    color: var(--text-color);
    font-size: 60px;
    margin-bottom: 80px;
    z-index: 2; /* Ensure the title is above the overlay */
    position: relative;
    text-align: center;
}

/* Container for the login page */
.login-container {
    position: relative;
    display: flex;
    flex-direction: column; /* Stack title and login-box vertically */
    justify-content: center; /* Center everything vertically */
    align-items: center; /* Center everything horizontally */
    height: 100vh;
    background: url('../../../assets/images/noise_texture.png') center/cover no-repeat;
}

/* Background overlay */
.login-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color); /* Use your preferred color */
    opacity: 0.85; /* 85% transparency */
    z-index: 1;
}

/* Ensure the login box content is on top of the overlay */
.login-box {
    position: relative;
    z-index: 2;
    padding: var(--spacing-large);
    border-radius: 20px; /* Rounded corners */
    box-shadow: var(--box-shadow);
    background: #737373; /* Your desired background color */
    width: 300px; /* Set a fixed width for the login box */
    max-width: 90%; /* Ensure responsiveness */
    text-align: center; /* Center all text inside the login box */
    
    /* Gradient border with rounded corners */
    border: 2px solid transparent; /* Fallback border for older browsers */
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-image: linear-gradient(rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 0.7)), /* The same transparent background color */
                    var(--hover-gradient); /* Gradient border */

                      /* linear-gradient(120deg, var(--primary-color), var(--secondary-color)); */
}


/* Headings inside the login box */
.login-box h2 {
    font-family: var(--font-secondary);
    color: var(--text-color);
    margin-bottom: var(--spacing-medium);
    text-align: center;
}

/* Input field styling */
.input-group {
    margin-bottom: var(--spacing-medium);
    text-align: left; /* Align labels to the left */
}

.input-group label {
    display: block;
    color: var(--text-color);
    margin-bottom: var(--spacing-small);
}

.input-group input {
    width: 100%;
    padding: var(--spacing-small);
    background: #ffffff; /* Darker input background */
    border: 1px solid #555; /* Slightly lighter border */
    border-radius: 8px; /* Rounded corners for inputs */
    color: var(--background-color); /* Light grey text color */
    font-family: var(--font-secondary);
}

/* Forgot password link */
.forgot-password {
    color: var(--text-color);
    font-size: 8px;
    text-decoration: none;
    display: block;
    margin-top: var(--spacing-small);
    margin-bottom: var(--spacing-medium);
    text-align: right;
}

/* Submit button styling */
.btn {
    background-color: var(--primary-color);
    color: #fff; /* White text */
    font-weight: bold; /* Bold text */
    width: 100%;
    padding: var(--spacing-medium);
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    font-size: 16px;
    /* margin-bottom: var(--spacing-medium); */
    cursor: pointer;
    border: none;
}

/* Social login container */
.social-login {
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacing-medium);
}

.social-btn {
    flex: 1;
    margin: 0 5px;
    padding: 12px 0; /* More padding for the social buttons */
    border-radius: var(--border-radius);
    font-size: 16px;
    color: var(--text-color);
    cursor: pointer;
    background-color: #fff; /* Change background to white */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc; /* Lighter border to match the white background */
    transition: background-color 0.3s ease; /* Smooth transition for hover effects */
}

.social-btn:hover {
    background-color: #f0f0f0ce; /* Slightly darker white for the hover effect */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for hover effect */
}

/* Text for "or continue with" */
.login-box p {
    color: #fff; /* White text for "or continue with" */
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
}

.social-logo {
    width: 20px; /* Adjusted size to better match the expected version */
    height: 20px; /* Adjusted size to better match the expected version */
}

/* Register text styling */
.register-text {
    font-size: 8px; /* 8px font size */
    color: #fff; /* White text color */
    margin-top: var(--spacing-medium); /* Small margin at the top */
    text-align: center; /* Center the text */
    margin-bottom: var(--spacing-medium); /* Space at the bottom for better spacing */
}

/* Bold the "Register for free" part */
.register-link {
    font-weight: bold;
    color: var(--text-color); /* Optional: Different color for the link */
    cursor: pointer; /* Show pointer cursor to indicate it's clickable */
}