:root {
    /* Colors */
    --primary-color: #39FF14; /* Neon Green */
    --primary-color-rgb: 57, 255, 20; /* RGB values of the primary color */
    --secondary-color: #6A0DAD; /* Vibrant Purple */
    --third-color: #007BFF;
    --background-color: #333333; /* Dark Charcoal */
    --text-color: #F5F5F5; /* Soft White */
    --accent-color: #FF6F61; /* Coral Red */
    
    /* Fonts */
    --font-primary: 'Pixel Operator 8';
    --font-secondary: 'Gilroy', sans-serif;

    /* Spacing */
    --spacing-small: 8px;
    --spacing-medium: 16px;
    --spacing-large: 32px;

    /* Border Radius */
    --border-radius: 15px;

    /* Box Shadow */
    --box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);

    --padding: 20px;
    --sidebar-width: 80px;
    --sidebar-button-size: 60px;
    --logo-font-size: 24px;
    --transition-duration: 0.3s;

    --scrollbar-width: 12px;
    --scrollbar-track-color: #f1f1f1;
    --scrollbar-thumb-color: #888;
    --scrollbar-thumb-hover-color: #555;
    /* --hover-gradient: linear-gradient(
        135deg,
        rgba(0, 123, 255, 0.4) 0%,    
        rgba(106, 13, 173, 0.3) 25%,   
        rgba(39, 174, 96, 0.4) 50%,    
        rgba(245, 59, 87, 0.4) 75%,    
        rgba(255, 195, 0, 0.3) 100%    
    ); */
    --hover-gradient: linear-gradient(135deg, 
    rgba(0, 123, 255, 0.7) 0%,
    rgba(106, 13, 173, 0.5) 25%,
    rgba(57, 255, 20, 0.4) 50%,
    rgba(51, 51, 51, 0.6) 75%,
    rgba(255, 111, 97, 0.7) 100%);
}