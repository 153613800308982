/* Sidebar.css */
/* Sidebar styling */
.sidebar {
    width: auto; /* Take up full width of sidebar wrapper */
    height: auto; /* Take up full height of sidebar wrapper */
    background: linear-gradient(to bottom, rgba(42, 42, 42, 0.9), rgba(26, 26, 26, 0.9)); /* Sidebar background with transparency */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px; /* Optional: Add rounded corners */
}

.sidebar-btn {
    background: none;
    border: none;
    margin-bottom: var(--padding);
    padding: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* Rounded corners */
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
}

.sidebar-btn img {
    width: 50px;
    height: 50px;
    filter: grayscale(100%); /* Icons appear grayscale by default */
    transition: filter 0.3s ease;
}

.sidebar-btn:hover {
    /* background: var(--primary-color); Change background on hover */
    transform: scale(1.1); /* Slightly enlarge button on hover */
}

.sidebar-btn:hover img {
    filter: none; /* Remove grayscale on hover */
}