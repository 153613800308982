.background-layer {
    position: fixed; /* Fixes the background in place */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full height of the viewport */
    background: url('../../../assets/images/pexels-slendyalex-3745234.jpg') center/cover no-repeat;
    background-color: #333333; /* Sets the background color to white */
    background-blend-mode: overlay; /* Blends the image with the overlay for a smooth effect */
    z-index: -1; /* Moves the background behind everything else */
    filter: brightness(0.9) contrast(1.1) saturate(1.4); /* Enhances the image quality */
    animation: subtlePan 30s infinite alternate ease-in-out; /* Smooth pan animation */
}

/* Background overlay */
.background-layer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(270deg,rgba(51, 51, 51, 0.8), rgba(57, 255, 20, 0.5));  */
    background: linear-gradient(135deg, 
                rgba(0, 123, 255, 0.7) 0%,
                rgba(106, 13, 173, 0.5) 25%,
                rgba(57, 255, 20, 0.4) 50%,
                rgba(51, 51, 51, 0.6) 75%,
                rgba(255, 111, 97, 0.7) 100%);
    /* background: linear-gradient(135deg, 
                rgba(0, 123, 255, 0.8) 0%,
                rgba(106, 13, 173, 0.6) 40%,
                rgba(51, 51, 51, 0.5) 100%); */
    /* background: linear-gradient(135deg, 
                rgba(0, 180, 255, 0.8) 0%,
                rgba(123, 50, 250, 0.7) 35%,
                rgba(255, 175, 189, 0.6) 100%); */
    /* background: linear-gradient(135deg, 
                rgba(85, 98, 112, 0.9) 0%,
                rgba(255, 107, 129, 0.8) 50%, 
                rgba(245, 175, 95, 0.7) 100%); */
    opacity: 0.45; /* 85% transparency */
    z-index: 1;
    backdrop-filter: blur(100px); /* Adds a slight blur to the overlay */
}

/* Smooth pan animation */
@keyframes subtlePan {
    0% { background-position: center; }
    100% { background-position: center 10%; }
}

/* Wrapper for Sidebar */
.sidebar-wrapper {
    width: 125px; /* Fixed width of the sidebar */
    height: 100vh; /* Full height of the viewport */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    position: fixed; /* Keep it fixed on the left */
    top: 0;
    left: 0;
    z-index: 1000; /* Ensure it stays on top of other elements */
    overflow: hidden;
    transform: translateX(0); /* Start slightly off-screen for a dramatic entrance */
    transition: transform 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Ensure transition is applied */
}


/* Hide the sidebar by moving it off-screen */
.sidebar-wrapper.hidden {
    transform: translateX(-150px); /* Moves the sidebar out of view */
}

/* Reveal the sidebar with an epic transition */
.sidebar-wrapper.visible {
    transform: translateX(0); /* Brings the sidebar fully into view */
}

/* Ensure that the content area (right of the sidebar) is a flex container */
.content-area {
    display: flex;
    flex-direction: column; /* Arrange items in a column */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    height: 100vh; /* Full height of the viewport */
    padding-left: 125px; /* Account for the sidebar width */
    transition: margin-left 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55), width 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Smooth transition matching the sidebar */
}

/* Adjust the content area when the sidebar is hidden */
.content-area.full-width {
    margin-left: 0; /* Removes the left margin */
    width: 100%; /* Full width when sidebar is hidden */
    padding-left: 0px; /* Account for the sidebar width */
}

/* Styles for the .background-episode-page */
.background-episode-page {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(42, 42, 42, 0.9), rgba(26, 26, 26, 0.9)); /* Sidebar background with transparency */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    flex-direction: column; /* Ensure the content is arranged vertically */
    box-sizing: border-box; /* Ensure padding and border are included in the width and height */
    padding: 2%; /* Add some padding to the inside of the container */
}

/* Add top, bottom, and right margins using flexbox */
.background-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
    width: calc(100% - 2%); /* Subtract right padding from width */
    height: calc(100% - 4%); /* Subtract top and bottom padding from height */
    padding: 1% 1% 1% 0%; /* Add top, bottom, and right padding */
    box-sizing: border-box; /* Include padding in the total height */
}

.background-wrapper.hidden-sidebar {
    padding: 1%;
}

.page-title {
    border-bottom: 2px solid var(--background-color); /* Line below the title */
    margin: 0; /* Ensure no margin pushing it down */
    padding: 0 16px; /* Only horizontal padding */
    padding-bottom: 16px; /* Additional padding to push the line down */
    width: 100%; /* Full width */
    flex-shrink: 0; /* Prevent the title from shrinking */
    box-sizing: border-box; /* Include padding and border in the total width/height */
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: flex-start;
}

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.episode-id-box {
    font-size: 0.8em;
    padding: 4px 8px;
    background: linear-gradient(135deg, rgba(0, 123, 255, 0.7), rgba(106, 13, 173, 0.7));
    border-radius: 8px;
    color: #ffffff;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    animation: glowPulse 2s infinite ease-in-out;
}

@keyframes glowPulse {
    0% {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.7), 0 0 10px rgba(106, 13, 173, 0.7);
    }
    50% {
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.9), 0 0 15px rgba(106, 13, 173, 0.9);
    }
    100% {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.7), 0 0 10px rgba(106, 13, 173, 0.7);
    }
}

.page-title h1 {
    margin: 0; /* No margin around the h1 */
    padding: 0;
    font-family: var(--font-secondary);
    color: var(--text-color);
    /* font-size: 24px; */
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.page-button {
    position: relative;
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    color: #ffffff;
    font-family: var(--font-secondary);
    font-weight: bold;
    background: var(--hover-gradient);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    z-index: 1;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.page-button::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(210deg, 
        rgba(255, 255, 255, 0.2), 
        rgba(255, 255, 255, 0) 70%
    );
    z-index: 0;
    opacity: 0.3;
    transition: transform 0.5s ease;
    transform: rotate(45deg);
    animation: rotatingHighlight 6s infinite linear;
}

.page-button:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

.page-button:hover::before {
    transform: rotate(90deg);
}

.page-button:active {
    transform: scale(0.98);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* activate for beautiful glowing */
/* @keyframes glowPulse {
    0% {
        box-shadow: 0 0 10px rgba(0, 255, 255, 0.6), 0 0 20px rgba(0, 255, 255, 0.4);
    }
    50% {
        box-shadow: 0 0 20px rgba(0, 255, 255, 0.8), 0 0 30px rgba(0, 255, 255, 0.6);
    }
    100% {
        box-shadow: 0 0 10px rgba(0, 255, 255, 0.6), 0 0 20px rgba(0, 255, 255, 0.4);
    }
} */

@keyframes rotatingHighlight {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Toggle Sidebar Button */
.toggle-sidebar-btn {
    position: fixed; /* Change to fixed to stick to the bottom of the viewport */
    z-index: 2000;
    bottom: 20px; /* Distance from the bottom of the viewport */
    left: 20px; /* Adjust to position horizontally (left side) */
    width: 50px;
    height: 50px;
    background-color: transparent; /* No background */
    border: none;
    cursor: pointer;
    padding: 0; /* Remove any padding */
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(100%); /* Icons appear grayscale by default */
    transition: transform 0.3s ease; /* Add easing for a smoother transform transition */
}

.toggle-sidebar-btn img {
    width: 50px; /* Adjust size as needed */
    height: 50px;
}

.toggle-sidebar-btn:hover {
    transform: scale(1.1); /* Slightly enlarge the button on hover */
}