/* Ensure the content area has a fixed height */
.episode-detail {
    /* max-height: calc(100vh - 200px); Adjust this value based on your header height */
    overflow-y: auto;
    padding-right: 10px;
    scrollbar-width: thin;
    scrollbar-color: #888 rgb(77, 72, 72,0);
}

/* Custom scrollbar for WebKit browsers */
.episode-detail::-webkit-scrollbar {
    width: 12px;
}

.episode-detail::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.episode-detail::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.episode-detail::-webkit-scrollbar-thumb:hover {
    background: #fff;
}

.loading, .error {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    color: var(--text-color);
}

.error {
    color: #ff6b6b;
}

.no-data {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    color: var(--text-color);
}

.episode-detail .section-content {
    padding: 10px;
}

.episode-detail .section-content > * + * {
    margin-top: 10px;
}

.custom-block {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white; /* Text color for better contrast */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Padding for content */
    margin-bottom: 10px; /* Space between blocks */
}

.building-block {
    background: #f9f9f9; /* Simpler background */
    color: #333; /* Darker text color */
    border: 1px solid #ddd; /* Light border */
    border-radius: 4px; /* Slightly rounded corners */
    padding: 10px; /* Padding for content */
    margin-bottom: 10px; /* Space between blocks */
}