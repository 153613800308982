.table-container {
    font-family: var(--font-secondary);
    height: calc(100vh - 240px); /* Increased top margin */
    width: 95%; /* Increased width */
    max-width: 1200px; /* Set a max-width for larger screens */
    margin: 20px auto; /* Center the container */
    border-radius: 12px;
    overflow: auto;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    background-color: var(--background-color);
}

/* Custom scrollbar for WebKit browsers */
.table-container::-webkit-scrollbar {
    width: 12px; /* Adjust scrollbar width */
}

.table-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.styled-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.styled-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--background-color);
}

.styled-table th,
.styled-table td {
    padding: 16px 20px;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.styled-table th {
    background-color: rgba(255, 255, 255, 0.03);
    color: var(--text-color);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.8px;
}

.styled-table tbody tr {
    transition: all 0.3s ease;
}

.styled-table tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.styled-table tbody td {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
}

.ended-reason {
    background-color: var(--accent-color);
    padding: 5px 10px;
    border-radius: 5px;
    color: var(--background-color);
    font-weight: bold;
    display: inline-block;
    width: fit-content;
}

.no-data-message {
    padding: 60px 40px;
    text-align: center;
    font-size: 18px;
    color: var(--text-color);
    background-color: rgba(255, 255, 255, 0.02);
    border-radius: 8px;
}

.status {
    padding: 8px 14px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: inline-block;
}

.status.generated {
    background-color: rgba(76, 175, 80, 0.15);
    color: #4CAF50;
}

.status.pending {
    position: relative;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.loading-circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top-color: #FFA500;
    animation: spin 1s linear infinite, gradient 3s ease-in-out infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes gradient {
    0%, 100% { border-top-color: #FFA500; }
    33% { border-top-color: #FF4500; }
    66% { border-top-color: #8A2BE2; }
}

.loading, .error {
    padding: 60px 40px;
    text-align: center;
    font-size: 18px;
    color: var(--text-color);
}

.error {
    color: #ff6b6b;
}

/* Responsive design */
@media (max-width: 768px) {
    .table-container {
        height: calc(100vh - 180px); /* Adjusted for mobile */
        width: 100%; /* Slightly increased width for mobile */
        margin: 15px auto; /* Reduced margin for mobile */
    }

    .styled-table th,
    .styled-table td {
        padding: 12px 16px;
        font-size: 12px;
    }

    .status {
        padding: 6px 10px;
        font-size: 10px;
    }
}