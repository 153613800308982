.upload-episode-view {
    height: calc(100vh - 200px); /* Adjust this value based on your header height */
    overflow-y: auto;
    padding-right: 10px;
    scrollbar-width: thin;
    scrollbar-color: #888 rgb(77, 72, 72,0);
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

/* Custom scrollbar for WebKit browsers */
.upload-episode-view::-webkit-scrollbar {
    width: 12px;
}

.upload-episode-view::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.upload-episode-view::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.upload-episode-view::-webkit-scrollbar-thumb:hover {
    background: #fff;
}

.upload-status-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.9);
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    z-index: 1000;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    max-width: 90%;
    width: 400px;
}

.upload-status-popup h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.upload-status-popup p {
    color: white;
    margin-bottom: 1.5rem;
}

.status-action-btn {
    padding: 0.75rem 2rem;
    font-size: 1rem;
    color: white;
    background-color: darkblue;
    background-image: var(--hover-gradient);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease, box-shadow 0.3s ease;
}

.status-action-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

.status-action-btn:active {
    transform: scale(0.98);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.upload-status-popup.success .status-action-btn {
    background-color: var(--primary-color);
}

.upload-status-popup.fail .status-action-btn {
    background-color: var(--accent-color);
}

@media (max-width: 768px) {
    .upload-status-popup {
        padding: 1.5rem;
    }

    .status-action-btn {
        padding: 0.6rem 1.5rem;
        font-size: 0.9rem;
    }
}
