.builders-container {
    height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 20px;
    padding-right: 30px;
    scrollbar-width: thin;
    scrollbar-color: #888 rgb(77, 72, 72, 0);
}

/* Custom scrollbar for WebKit browsers */
.builders-container::-webkit-scrollbar {
    width: 12px;
}

.builders-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.builders-container::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.builders-container::-webkit-scrollbar-thumb:hover {
    background: #fff;
}

.no-builders-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 18px;
    color: var(--text-color);
    text-align: center;
}

.builders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.builder-item-wrapper {
    position: relative;
}

.builder-item-container {
    position: relative;
    background-color: #444;
    border-radius: 8px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.builder-item-container:hover,
.builder-item-container.active {
    z-index: 2;
    background-color: #555;
    background-image: var(--hover-gradient);
    filter: brightness(0.9) contrast(1.1) saturate(1.4);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.builder-item {
    flex-grow: 1;
    border: none;
    cursor: pointer;
    text-align: left;
    background: none;
    color: white;
    padding: 0;
    margin-right: 10px;
}

.builder-item h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    text-shadow: 0 0 8px rgba(233, 236, 239, 0.6);
    transition: text-shadow 0.3s ease;
}

.builder-item-container:hover h3,
.builder-item-container.active h3 {
    animation: textGlow 1.5s infinite alternate;
}

.menu-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.builder-item-container:hover .menu-button,
.builder-item-container.active .menu-button {
    opacity: 1;
}

.menu-button img {
    width: 20px;
    height: 20px;
    filter: invert(1);
}

.context-menu {
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    z-index: 1002;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 200px;
}

.context-menu button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.context-menu button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002; /* Ensure it's above the context menu */
}

.modal-content {
    background-color: var(--background-color);
    padding: 20px;
    border-radius: var(--border-radius);
    max-width: 400px;
    width: 100%;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-actions button {
    margin-left: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-actions button:first-child {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.modal-actions button:last-child {
    background-color: var(--secondary-color);
    color: var(--text-color);
}

.modal-actions button:hover {
    opacity: 0.8;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Just below the context menu */
}

@keyframes textGlow {
    0% {
        text-shadow: 0 0 8px rgba(233, 236, 239, 0.3);
    }
    50% {
        text-shadow: 0 0 15px rgba(58, 150, 255, 0.8);
    }
    100% {
        text-shadow: 0 0 8px rgba(233, 236, 239, 0.3);
    }
}

@media (max-width: 768px) {
    .builders-container {
        height: calc(100vh - 180px);
        padding-right: 20px;
    }
}

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.error-message {
    color: #ff0000;
    margin-bottom: 1rem;
}

.retry-button {
    padding: 0.5rem 1rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.retry-button:hover {
    background-color: #2980b9;
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* Remove the spinner and loading text styles */
.spinner, .loading-spinner p {
    display: none;
}

.applying-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: var(--text-color);
    animation: fadeIn 0.3s ease-out;
    border-radius: 8px;
}

.applying-overlay p {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.apply-status {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 4px;
    animation: fadeIn 0.3s ease-out;
}

.apply-status.success {
    background-color: rgba(0, 255, 0, 0.2);
    color: #00ff00;
}

.apply-status.error {
    background-color: rgba(255, 0, 0, 0.2);
    color: #ff0000;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* ... rest of the existing styles ... */